import { Col, Dropdown, MenuProps, Row } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import LogoIcon from "@/assets/icons/Logo";
import routes from "@/routes";
import ButtonRectangle from "@/components/ButtonRectangle";
import styles from "../../siteProductManagement.module.less";
import { useTypedSelector } from "@/app/store";
import { useState } from "react";
import CompanyDropdownList from "@/components/FilterSelectCustom/CompanyDropdownList";
import WarehouseCodeDropdownList from "@/components/FilterSelectCustom/WarehouseCodeDropdownList";
import { WarehouseType } from "@/utils/types";
import clsx from "clsx";
import PrevIcon from "@/assets/icons/PrevIcon";
import { CompanySelectType } from "@/modules/WarehouseManagement/warehouseManagement.slice";
// import { siteProductManagementSlide } from "../../siteProductManagement.slice";

interface IFilterComponentProps {
  type: "create" | "edit" | "view" | "list";
  onSelectCompany?: (value: CompanySelectType) => void;
  onSelectWareHouse?: (value: WarehouseType | null) => void;
  onPrint?: () => void;
}

function FilterComponent({ type, onSelectCompany, onSelectWareHouse, onPrint }: IFilterComponentProps) {
  const history = useHistory();
  const location = useLocation();

  const [searchWarehouse, setSearchWarehouse] = useState("");
  const [searchCompany, setSearchCompany] = useState("");

  const { companySelected, wareHouseSelected } = useTypedSelector((store) => store.siteProductManagementSlide);

  const renderTitle = () => {
    let title = "";
    switch (type) {
      case "create":
        title = routes.createSiteProduct.title;
        break;
      case "edit":
        title = routes.editSiteProduct.title;
        break;
      case "view":
        title = routes.viewSiteProduct.title;
        break;
      default:
        title = "Product management";
        break;
    }
    return title;
  };

  const shouldDisableFilter = ["edit", "view"].includes(type);

  const items: MenuProps["items"] = [
    {
      label: "Import file",
      key: "1",
      onClick: () => history.push(routes.productImportFile.path),
    },
    {
      label: "Download template",
      key: "2",
      onClick: () => {
        const link = document.createElement("a");
        link.href = `/files/product_template.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <Row justify="space-between" align="middle" className={styles.filterArea}>
      <Col
        flex={1}
        style={{
          height: "100%",
        }}
      >
        <div className={styles.leftContent}>
          {(type === "create" || type === "edit") && (
            <div
              className="center-element"
              style={{
                width: 42,
                background: "#D3D7E1",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => history.push(type === "create" || type === "edit" ? routes.siteProductList.path : location.pathname)}
            >
              <PrevIcon />
            </div>
          )}
          <div className={styles.logoWrapper}>
            {type === "list" && <LogoIcon />}
            <span
              style={{
                fontSize: 14,
                color: "#234C66",
                fontWeight: 700,
              }}
            >
              {renderTitle()}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              gap: 16,
            }}
          >
            <CompanyDropdownList
              value={companySelected?.companyName || companySelected?.id || null}
              search={searchCompany}
              onChange={(value: any) => {
                onSelectCompany?.(JSON.parse(value || null));
                setSearchCompany("");
              }}
              disabled={shouldDisableFilter}
              isHideOptionAll={type !== "list"}
            />
            {type === "list" && (
              <WarehouseCodeDropdownList
                placeholder={wareHouseSelected?.identityCode}
                value={wareHouseSelected?.identityCode || null}
                search={searchWarehouse}
                onChange={() => {
                  setSearchWarehouse("");
                }}
                onChangeWareHouse={onSelectWareHouse}
                filter={{
                  companyId: companySelected?.id,
                  ignoreDeleted: true,
                }}
                skipByCompany={!companySelected}
                disabled={false}
              />
            )}
          </div>
        </div>
      </Col>
      {type === "list" && (
        <Col
          style={{
            height: "100%",
          }}
        >
          <div className={styles.rightContent}>
            <Link to={routes.createSiteProduct.path}>
              <ButtonRectangle>New Site Product</ButtonRectangle>
            </Link>

            <Dropdown
              menu={menuProps}
              trigger={["click"]}
              // eslint-disable-next-line
              dropdownRender={(menu) => <div className={clsx(styles.export_group_button)}>{menu}</div>}
            >
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <ButtonRectangle
                  style={{
                    background: "#07657A",
                    height: 36,
                  }}
                >
                  Import Excel file
                </ButtonRectangle>
              </div>
            </Dropdown>

            <ButtonRectangle
              style={{
                background: "#8491A6",
              }}
              onClick={onPrint}
            >
              Export QR list
            </ButtonRectangle>

            {/* <Dropdown
              menu={menuProps}
              trigger={["click"]}
              // eslint-disable-next-line
              dropdownRender={(menu) =>  <div className={clsx(styles.export_group_button)}>{menu}</div>}
            >
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <ButtonRectangle
                  style={{
                    background: "#07657A",
                    height: 36,
                  }}
                >
                  Export QR list
                </ButtonRectangle>
              </div>
            </Dropdown> */}
          </div>
        </Col>
      )}
    </Row>
  );
}

export default FilterComponent;
