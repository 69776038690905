import { Image, Space, notification } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import DownloadIcon from "@/assets/icons/DownloadIcon";
import DeleteIcon from "@/assets/icons/DeleteIcon";
import EditIcon from "@/assets/icons/EditIcon";
import NextIcon from "@/assets/icons/NextIcon";
import routes from "@/routes";
import { CompanyType } from "@/utils/types";
import C from "@/utils/constants";
import { useExportProductMutation } from "@/rtk-query/productQueries";
import { getError } from "@/utils/helpers";

const renderDownloadIcon = (title?: string, onClick?: VoidFunction, disable?: boolean) => (
  <div
    style={{
      color: disable ? "#524e4eab" : "#192EEB",
      cursor: disable ? "not-allowed" : "pointer",
    }}
    onClick={!disable ? () => onClick?.() : () => undefined}
  >
    <p
      style={{
        fontSize: 10,
        margin: 0,
      }}
    >
      {title}
    </p>
    <DownloadIcon
      color={disable ? "#524e4eab" : "#192EEB"}
      style={{
        width: 16,
      }}
    />
  </div>
);

export const columns = ({
  onDelete,
  onRestore,
  onExport,
  loadingList,
}: {
  onDelete?: (id: string | number) => void;
  onRestore?: (id: string | number) => void;
  onExport?: (id: string | number, type: "warehouse" | "user" | "product") => void;
  isLoadingExportUserApi?: boolean;
  isLoadingExportWarehouseApi?: boolean;
  loadingList?: Array<string | number>;
}) => {
  // eslint-disable-next-line
  const [exportProductApi] = useExportProductMutation();
  const handleExportProduct = async (companyId: string | number) => {
    try {
      await exportProductApi({
        companyId,
        isLoading: undefined,
      }).unwrap();
    } catch (error) {
      notification.error({
        message: getError(error),
        key: "product",
      });
    }
  };

  return [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <div
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 120,
          }}
        >
          {index + 1}
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 120,
            display: "block",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 120,
            display: "block",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (_, record) => (
        <div
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 120,
          }}
        >
          {`${record?.phoneExt} ${record?.phoneNumber}`}
        </div>
      ),
    },
    {
      title: "Number Of Warehouse",
      dataIndex: "numberOfWarehouse",
      key: "numberOfWarehouse",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Number Of Product",
      dataIndex: "numberOfProduct",
      key: "numberOfProduct",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Number Of Users",
      dataIndex: "numberOfUser",
      key: "numberOfUser",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "URL Logo",
      key: "logoUrl",
      dataIndex: "logoUrl",
      render: (src, record) => (
        <Image
          src={src}
          style={{
            width: 40,
            opacity: record?.deletedAt ? 0.6 : 1,
          }}
          loading="eager"
          fallback={C.IMAGE_DEFAULT}
        />
      ),
    },
    {
      title: "Functions",
      key: "action",
      render: (_, record) => {
        const isLoading = !!(loadingList || []).find((item) => item === record?.id);

        return (
          <Space
            size="middle"
            style={{
              maxWidth: 380,
              fontSize: 12,
            }}
          >
            {record?.deletedAt ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                  color: "#192EEB",
                  cursor: "pointer",
                  justifyContent: "center",
                  width: "100%",
                }}
                onClick={() => (isLoading ? undefined : onRestore?.(record?.id))}
              >
                <span>Restore</span>
                <span>&gt;&gt;</span>
              </div>
            ) : (
              <>
                {renderDownloadIcon("List of warehouses", () => onExport?.(record?.id || "", "warehouse"))}
                {renderDownloadIcon("List of users", () => onExport?.(record?.id || "", "user"))}
                {renderDownloadIcon("List of products", () => handleExportProduct(record?.id))}
                <Link to={routes.viewClient.path.replace(":id", record?.id || "")}>
                  <NextIcon style={{ width: 16, cursor: "pointer" }} />
                </Link>
                <Link to={routes.editClient.path.replace(":id", record?.id || "")}>
                  <EditIcon style={{ width: 16, cursor: "pointer" }} />
                </Link>
                <div onClick={() => (isLoading ? undefined : onDelete?.(record?.id || ""))}>
                  <DeleteIcon style={{ width: 16, cursor: "pointer" }} />
                </div>
              </>
            )}
          </Space>
        );
      },
    },
  ] as ColumnsType<CompanyType>;
};
