import C from "./constants";
import { getLocalStorage } from "./helpers";

type RefreshTokenType = {
  statusCode?: number;
  accessToken?: string;
  refreshToken?: string;
};

export default class ApiServices {
  protected token: string;

  private baseUrl: string;

  private configs: any;

  constructor(token?: string) {
    this.token = token || "";
    this.baseUrl = process.env.REACT_APP_API_URL as string;
    this.configs = {
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
    };
  }

  public async signIn(data: { username: string; password: string }) {
    try {
      const response: any = await fetch(`${this.baseUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: data.username,
          password: data.password,
        }),
      }).then((res) => res.json());
      if (response.statusCode === 200) {
        return response.data;
      }
      return response;
    } catch (error) {}
  }

  public async refreshToken(refreshToken: string) {
    try {
      const response: RefreshTokenType = await fetch(`${this.baseUrl}/refresh`, {
        ...this.configs,
        method: "POST",
        body: JSON.stringify({
          refreshToken,
        }),
      }).then((res) => res.json());
      return response;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public async logout() {
    const token = getLocalStorage(C.STORAGE_TOKEN);
    if (!token) return;
    try {
      const response: any = await fetch(`${this.baseUrl}/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json());
      if (response.statusCode === 200) {
        return response.data;
      }
      return response;
    } catch (error) {}
  }
}
