import { Space, notification } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import DownloadIcon from "@/assets/icons/DownloadIcon";
import DeleteIcon from "@/assets/icons/DeleteIcon";
import EditIcon from "@/assets/icons/EditIcon";
import NextIcon from "@/assets/icons/NextIcon";
import routes from "@/routes";
import { WarehouseType } from "@/utils/types";
import { useExportProductMutation } from "@/rtk-query/productQueries";
import { getError } from "@/utils/helpers";

const renderDownloadIcon = (title?: string, onClick?: VoidFunction, disable?: boolean) => (
  <div
    style={{
      color: disable ? "#524e4eab" : "#192EEB",
      cursor: disable ? "not-allowed" : "pointer",
    }}
    onClick={onClick}
  >
    <p
      style={{
        fontSize: 10,
        margin: 0,
      }}
    >
      {title}
    </p>
    <DownloadIcon
      color={disable ? "#524e4eab" : "#192EEB"}
      style={{
        width: 16,
      }}
    />
  </div>
);

export const columns = ({
  onDelete,
  onRestore,
  loadingList,
}: {
  onDelete?: (id: string | number) => void;
  onRestore?: (id: string | number) => void;
  loadingList?: Array<string | number>;
}) => {
  // eslint-disable-next-line
  const [exportProductApi] = useExportProductMutation();
  const handleExportProduct = async (warehouseId: string | number) => {
    try {
      await exportProductApi({
        warehouseId,
        isLoading: undefined,
      }).unwrap();
    } catch (error) {
      notification.error({
        message: getError(error),
        key: "product",
      });
    }
  };

  return [
    {
      title: "No.",
      render: (_, record, index) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 120,
            display: "block",
          }}
        >
          {index + 1}
        </span>
      ),
    },
    {
      title: "Warehouse Code",
      dataIndex: "identityCode",
      key: "identityCode",
      width: 150,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 150,
            display: "block",
          }}
        >
          {text}
        </span>
      ),
    },

    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 150,
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 150,
            display: "block",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Company",
      dataIndex: "companyId",
      key: "companyId",
      width: 150,
      render: (_, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
            maxWidth: 150,
            display: "block",
          }}
        >
          {record?.company?.companyName}
        </span>
      ),
    },
    {
      title: "Number Of Bin",
      dataIndex: "numberOfBin",
      key: "numberOfBin",
      render: (text, record) => (
        <span
          style={{
            color: record?.deletedAt ? "#5B5959" : "#000000E0",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Functions",
      key: "action",
      render: (_, record) => {
        const loading = !!loadingList?.find((item) => item === record?.id);
        return (
          <Space
            size="middle"
            style={{
              maxWidth: 380,
              fontSize: 12,
            }}
          >
            {record?.deletedAt ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                  color: "#192EEB",
                  cursor: "pointer",
                  justifyContent: "center",
                  width: "100%",
                }}
                onClick={() => (loading ? undefined : onRestore?.(record?.id))}
              >
                <span>Restore</span>
                <span>&gt;&gt;</span>
              </div>
            ) : (
              <>
                {renderDownloadIcon("List of products", () => handleExportProduct(record?.id))}
                <Link to={routes.viewWarehouse.path.replace(":id", record.id)}>
                  <NextIcon style={{ width: 16, cursor: "pointer" }} />
                </Link>
                <Link to={routes.editWarehouse.path.replace(":id", record.id)}>
                  <EditIcon style={{ width: 16, cursor: "pointer" }} />
                </Link>
                <div onClick={() => (loading ? undefined : onDelete?.(record?.id))}>
                  <DeleteIcon style={{ width: 16, cursor: "pointer" }} />
                </div>
              </>
            )}
          </Space>
        );
      },
    },
  ] as ColumnsType<WarehouseType>;
};
