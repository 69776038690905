import MultiBoxIcon from "@/assets/icons/MultiBoxIcon";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import routes from "@/routes";
import styles from "./masterProductCUR.module.less";
import commonStyles from "@/styles/common.module.less";
import { Col, Form, Image, Input, Row, Select, Upload, notification } from "antd";
import clsx from "clsx";
import UploadImage from "@/assets/icons/UploadImage";
import { useHistory, useLocation, useParams } from "react-router";
import FilterComponent from "../MasterProductManagement/components/FilterComponent";
import ButtonNotched from "@/components/ButtonNotched";
import {
  useCreateMasterProductMutation,
  useEditMasterProductMutation,
  useGetMasterProductDetailQuery,
  useGetMasterProductUnitListQuery,
} from "@/rtk-query/masterProductQueries";
import { useEffect } from "react";
import { getError, objectToFormData, renderFieldViewOnly } from "@/utils/helpers";
import message from "@/utils/message";

interface IMasterProductCURModuleProps {
  type: "create" | "edit" | "view";
}

const MasterProductCURModule = ({ type }: IMasterProductCURModuleProps) => {
  const history = useHistory();
  const location = useLocation();
  const params: any = useParams();

  const [form] = Form.useForm();
  const fileWatcher = Form.useWatch("file", form);

  const { currentData: masterProductUnitList } = useGetMasterProductUnitListQuery({ isLoading: undefined });
  const { currentData: detailMasterProduct } = useGetMasterProductDetailQuery(params?.id, {
    skip: !params?.id,
  });
  const [createMasterProductApi, { isLoading: isLoadingCreate }] = useCreateMasterProductMutation();
  const [editMasterProductApi, { isLoading: isLoadingEdit }] = useEditMasterProductMutation();
  const isLoading = isLoadingCreate || isLoadingEdit;

  const initialData = () => {
    form.setFieldValue("manufacture", detailMasterProduct?.manufacture ?? "");
    form.setFieldValue("manufactureItemNumber", detailMasterProduct?.manufactureItemNumber ?? "");
    form.setFieldValue("productGroup", detailMasterProduct?.productGroup ?? "");
    form.setFieldValue("productName", detailMasterProduct?.productName ?? "");
    form.setFieldValue("minimumQuantity", detailMasterProduct?.minimumQuantity ?? "");
    form.setFieldValue("reorderMultiple", detailMasterProduct?.reorderMultiple ?? "");
    form.setFieldValue("unit", detailMasterProduct?.unit ?? "");
    form.setFieldValue("file", detailMasterProduct?.images?.[0]?.imageUrl ?? null);
  };

  const handleSubmit = async (values: any) => {
    const { productId, file, ...payload } = values;
    const isUpdateFile = typeof fileWatcher !== "string";
    const body = isUpdateFile
      ? {
          ...payload,
          file: fileWatcher?.file?.originFileObj,
        }
      : payload;

    try {
      type === "edit"
        ? await editMasterProductApi({
            id: params?.id,
            body: objectToFormData(body),
          }).unwrap()
        : await createMasterProductApi(objectToFormData(body)).unwrap();
      notification.success({
        key: "master_product_cur",
        message: message.SUCCESSFULLY,
      });
      history.push(routes.masterProductList.path);
    } catch (err) {
      notification.error({
        key: "master_product_cur",
        message: getError(err),
      });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!params?.id) {
      form.setFieldValue("productGroup", "");
      return;
    }
    initialData();
    return () => form.resetFields();
  }, [detailMasterProduct]);

  const titlePage = () => {
    let title = "";
    switch (type) {
      case "edit":
        title = routes.editMasterProduct.title;
        break;
      case "view":
        title = routes.viewMasterProduct.title;
        break;
      default:
        title = routes.createMasterProduct.title;
        break;
    }

    return title;
  };

  const viewOnly = type === "view";
  const canUpdate = type === "edit" || type === "create";

  const requiredValidate = (field: string) => ({
    required: true,
    validator: (_: any, value: string) => (value.trim().length > 0 ? Promise.resolve() : Promise.reject()),
    message: message.ERROR_NOT_BLANK(field),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckNumberKey = (evt: any) => {
    if (["e", "-", "+"].includes(evt.key.toLowerCase())) {
      evt.preventDefault();
    }
  };

  const findUnit = (masterProductUnitList?.data || []).find((unit) => unit.key === detailMasterProduct?.unit) || {
    key: detailMasterProduct?.unit,
    name: detailMasterProduct?.unit,
  };

  return (
    <ContentWrapper
      renderFilter={<FilterComponent type={type} />}
      dataBreadCrumb={[
        {
          title: routes.masterProductList.title,
          url: routes.masterProductList.path,
          icon: <MultiBoxIcon />,
        },
        {
          title: titlePage(),
          url: location.pathname,
          style: {
            fontWeight: 800,
          },
        },
      ]}
    >
      <Form name="basic" labelCol={{ xxl: 6, md: 7, sm: 8 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit} autoComplete="off" form={form}>
        <div className={styles.infoGeneral}>
          <Row
            gutter={[50, 8]}
            style={{
              marginBottom: 16,
            }}
          >
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Supplier</span>} name="manufacture" required rules={[requiredValidate("Manufacture")]}>
                {viewOnly ? (
                  renderFieldViewOnly(detailMasterProduct?.manufacture)
                ) : (
                  <Input className={clsx(commonStyles.textField)} size="large" placeholder="Type here..." />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span
                    className={commonStyles.label}
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Item code
                  </span>
                }
                name="manufactureItemNumber"
                required
                rules={[
                  {
                    required: true,
                    message: message.ERROR_NOT_BLANK("Item code."),
                  },
                ]}
              >
                {viewOnly ? (
                  renderFieldViewOnly(detailMasterProduct?.manufactureItemNumber)
                ) : (
                  <Input
                    className={clsx(commonStyles.textField)}
                    style={{
                      height: 38,
                    }}
                    size="large"
                    placeholder="Type here..."
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span
                    className={commonStyles.label}
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Product group
                  </span>
                }
                name="productGroup"
              >
                {viewOnly ? (
                  renderFieldViewOnly(detailMasterProduct?.productGroup)
                ) : (
                  <Input
                    className={clsx(commonStyles.textField)}
                    style={{
                      height: 38,
                    }}
                    size="large"
                    placeholder="Type here..."
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span
                    className={commonStyles.label}
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Product name
                  </span>
                }
                name="productName"
                required
                rules={[requiredValidate("Product name")]}
              >
                {viewOnly ? (
                  renderFieldViewOnly(detailMasterProduct?.productName)
                ) : (
                  <Input
                    className={clsx(commonStyles.textField)}
                    style={{
                      height: 38,
                    }}
                    size="large"
                    placeholder="Type here..."
                  />
                )}
              </Form.Item>
            </Col>
            {/* {type !== "create" && (
              <Col span={12}>
                <Form.Item
                  label={
                    <span
                      className={commonStyles.label}
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Stocktake‘s product ID
                    </span>
                  }
                  name="productId"
                >
                  {renderFieldViewOnly(detailMasterProduct?.id)}
                </Form.Item>
              </Col>
            )} */}
          </Row>
          <Row gutter={[50, 8]}>
            {/* <Col span={12}>
              <Form.Item
                label={<span className={commonStyles.label}>Max Par Level </span>}
                name="minimumQuantity"
                required
                rules={[
                  {
                    required: true,
                    message: message.ERROR_NOT_BLANK("Minimum order quantity"),
                  },
                ]}
              >
                {viewOnly ? (
                  renderFieldViewOnly(detailMasterProduct?.minimumQuantity)
                ) : (
                  <InputNumber
                    className={clsx(commonStyles.textField)}
                    size="large"
                    type="number"
                    style={{
                      maxWidth: 230,
                    }}
                    min={0}
                    placeholder="Type here..."
                    onKeyDown={handleCheckNumberKey}
                  />
                )}
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Re-order multiple</span>} name="reorderMultiple" initialValue={20}>
                {viewOnly ? (
                  renderFieldViewOnly(detailMasterProduct?.reorderMultiple)
                ) : (
                  <InputNumber
                    className={clsx(commonStyles.textField)}
                    size="large"
                    type="number"
                    min={0}
                    step={20}
                    style={{
                      maxWidth: 110,
                      paddingRight: 12,
                    }}
                    onKeyPress={handleCheckNumberKey}
                  />
                )}
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item label={<span className={commonStyles.label}>Unit</span>} name="unit" required rules={[requiredValidate("Unit")]}>
                {viewOnly ? (
                  renderFieldViewOnly(findUnit?.name)
                ) : (
                  <Select
                    className={commonStyles.select}
                    style={{
                      maxWidth: 110,
                    }}
                    size="large"
                    dropdownStyle={{
                      background: "#dfe3ef",
                      padding: 0,
                      borderRadius: 0,
                      boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.03)",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                    popupClassName="customDropdownSelectAntd"
                    placeholder=""
                    optionFilterProp="children"
                    allowClear
                  >
                    {(masterProductUnitList?.data || []).map(({ key, name }, index) => {
                      return (
                        <Select.Option value={key} key={index}>
                          {name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item name="file" label="">
          <Upload maxCount={1} showUploadList={false} fileList={[]} disabled={viewOnly} accept="image/jpeg, image/png, image/svg, image/gif">
            <div className={styles.uploadImageWrapper}>
              {typeof fileWatcher === "string" || fileWatcher?.file?.originFileObj ? (
                <>
                  <span>Image</span>
                  <Image
                    src={typeof fileWatcher === "string" ? fileWatcher : URL.createObjectURL(fileWatcher?.file?.originFileObj)}
                    preview={false}
                    style={{
                      maxWidth: 100,
                    }}
                  />
                </>
              ) : (
                <>
                  <span>Upload Image:</span>
                  <UploadImage />
                </>
              )}
            </div>
          </Upload>
        </Form.Item>

        {canUpdate && (
          <div className={styles.buttonGroup}>
            <ButtonNotched onClick={handleCancel} type="dark">
              {viewOnly ? "Back" : "Cancel"}
            </ButtonNotched>
            <button
              type="submit"
              style={{
                background: "transparent",
                border: "none",
              }}
              onClick={() => {
                const contentElement: any = document.getElementById("content");
                contentElement.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              disabled={isLoading}
            >
              <ButtonNotched>Save</ButtonNotched>
            </button>
          </div>
        )}
      </Form>
    </ContentWrapper>
  );
};

export default MasterProductCURModule;
