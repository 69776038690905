import { Empty, Table, TableProps } from "antd";
import clsx from "clsx";
import styles from "./tableCustom.module.less";
import message from "@/utils/message";

interface ITableCustomProps extends TableProps<any> {
  columns: Array<{ [key: string]: any }>;
  data: Array<{ [key: string]: any }>;
  loading?: boolean;
  total?: number;
  className?: string;
}

function TableCustom({ columns = [], data = [], loading = false, total, className, ...rest }: ITableCustomProps) {
  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={(data || []).map((item, index) => ({ key: index, ...item }))}
      className={clsx("tableCustom", styles.tableCustom, className)}
      pagination={false}
      locale={{
        emptyText: !loading ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={total === 0 ? message.NO_DATA : message.NO_MATCHING_RESULT} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Loading..." />
        ),
      }}
      {...rest}
    />
  );
}

export default TableCustom;
