/* eslint-disable react/no-unstable-nested-components */
import TableCustom from "@/components/TableCustom";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import { columns } from "./siteProductManagement.constants";
import routes from "@/routes";
import FilterComponent from "./components/FilterComponent";
import { Toast, useTypedDispatch, useTypedSelector } from "@/app/store";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import { siteProductManagementSlide } from "./siteProductManagement.slice";
import React, { useEffect, useRef, useState } from "react";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { Row, Spin, notification, Card, Button, Dropdown } from "antd";
import type { MenuProps } from "antd";
import { QRCodeCanvas } from "qrcode.react";
import C from "@/utils/constants";
import { checkTotalAllItem, getError } from "@/utils/helpers";
import message from "@/utils/message";
import { useDeleteProductMutation, useGetProductListQuery, useRestoreProductMutation } from "@/rtk-query/productQueries";
import { ProductType, WarehouseType } from "@/utils/types";
import SearchArea from "../MasterProductManagement/components/SearchArea";
import useDebounce from "@/hooks/useDebounce";
import { useReactToPrint } from "react-to-print";
import BoxIcon from "@/assets/icons/BoxIcon";
import { CompanySelectType } from "../WarehouseManagement/warehouseManagement.slice";
import clsx from "clsx";
import styles from "./siteProductManagement.module.less";
import FilterIcon from "@/assets/icons/FilterIcon";
import { TableRowSelection } from "antd/es/table/interface";

function SiteProductManagementModule() {
  const [data, setData] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [loadingList, setLoadingList] = useState<Array<string | number>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectAllPrint, setSelectAllPrint] = useState<boolean>(false);
  const [quantitySelected, setQuantitySelected] = useState<number>(0);
  const [selectPrintList, setSelectPrintList] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [exportExcel, setExportExcel] = useState<boolean>(false);
  const [binCode, setBinCode] = useState<string>("");
  const [listBindCode, setListBinCode] = useState<any[]>([]);
  const [dataBindCode, setdataBindCode] = useState<any[]>([]);
  const [position, setPosition] = useState<string>("");
  const [listPosition, setListPosition] = useState<any[]>([]);
  const [dataPosition, setdataPosition] = useState<any[]>([]);
  const searchDebounce = useDebounce(search, 300);
  const { companySelected, wareHouseSelected } = useTypedSelector((store) => store.siteProductManagementSlide);
  const [disable, setDisabled] = useState<boolean>(false);
  const { currentData, refetch } = useGetProductListQuery({
    page,
    companyId: companySelected?.id || "",
    warehouseId: wareHouseSelected?.id || "",
    pageSize: C.PAGE_SIZE,
    search: searchDebounce,
    isLoading: undefined,
  });

  const { currentData: userInfo } = useGetUserInfoQuery();
  const [deleteProductApi] = useDeleteProductMutation();
  const [restoreProductApi] = useRestoreProductMutation();

  const dispatch = useTypedDispatch();
  const { onSelectCompany, onSelectWareHouse } = siteProductManagementSlide.actions;

  const handleSelectCompany = (value: CompanySelectType) => {
    dispatch(onSelectCompany(value));
    setPage(1);
    setSelectedRowKeys([]);
    setSelectPrintList([]);
    setQuantitySelected(0);
    setDisabled(true);
  };

  const handleSelectWarehouse = (value: WarehouseType | null) => {
    dispatch(onSelectWareHouse(value));
    setPage(1);
    setSelectedRowKeys([]);
    setSelectPrintList([]);
    setQuantitySelected(0);
    setDisabled(true);
  };

  const handleLoaded = (id: string | number) => {
    setTimeout(() => {
      setLoadingList(loadingList.filter((item) => item !== id));
    }, 300);
  };

  const handleDeleteRestore = async (id: string | number, type?: "delete" | "restore") => {
    setLoadingList([...loadingList, id]);
    Toast.close();
    const contentElement: any = document.getElementById("content");
    contentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    try {
      type === "delete" ? await deleteProductApi(id).unwrap() : await restoreProductApi(id).unwrap();
      notification.success({
        message: message.SUCCESSFULLY,
      });
      handleLoaded(id);
      setPage(1);
      refetch();
    } catch (error) {
      notification.error({
        key: "error_product_del",
        message: getError(error),
      });
      handleLoaded(id);
      refetch();
    }
  };

  const handleDeletePopup = (id: string | number) => {
    Toast.delete({
      isOpen: true,
      title: "Are you sure you want to delete this product?",
      description: "You will not able to view and edit this product’s information.",
      cancelText: "Cancel",
      okText: "Delete",
      onCancel: () => {
        Toast.close();
      },
      onOk: () => handleDeleteRestore(id, "delete"),
      okStyle: {
        background: "#F32121",
      },
    });
  };

  useEffect(() => {
    handleSelectCompany({
      id: userInfo?.company?.id,
      companyName: userInfo?.company?.companyName,
      address: userInfo?.company?.address,
    });
  }, [userInfo]);

  // Intersection Observer
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    const paginationData = page === 1 ? currentData?.data || [] : [...data, ...(currentData?.data || [])];
    setData(paginationData);

    const listCode: Array<any> = [];
    const listBindCodeAdd: Array<any> = [];
    const listPosition: Array<any> = [];
    const listPositionAdd: Array<any> = [];
    const dataProduct = paginationData;

    for (let i = 0; i < dataProduct?.length; i++) {
      if (!listCode.includes(dataProduct[i].bin?.binCode)) {
        listCode.push(dataProduct[i].bin?.binCode);
      }
    }

    for (let i = 0; i < dataProduct?.length; i++) {
      if (!listPosition.includes(dataProduct[i]?.position)) {
        listPosition.push(dataProduct[i]?.position);
      }
    }

    listCode.forEach((item, index) => {
      listBindCodeAdd.push({
        label: item,
        key: index,
      });
    });

    listPosition.forEach((item, index) => {
      listPositionAdd.push({
        label: item,
        key: index,
      });
    });

    setListBinCode(listBindCodeAdd);
    setListPosition(listPositionAdd);
    setLoading(false);
  }, [currentData, refetch, page]);

  useEffect(() => {
    setLoading(true);
  }, [searchDebounce]);

  useEffect(() => {
    if (isVisible) {
      setPage((currentData?.page || 0) + 1);
    }
  }, [isVisible]);

  const refPrint = useRef<HTMLDivElement>(null);

  // Title FilterBinCode
  const TitleBinCode = (): JSX.Element => {
    const items: MenuProps["items"] | Array<any> = [];

    listBindCode.forEach((item) => {
      items.push({
        label: <div>{item.label === binCode ? <span style={{ color: "#F36F21" }}>{item.label}</span> : <span>{item.label}</span>}</div>,
        key: item.label,
        onClick: (e) => {
          if (e.key === binCode) {
            setBinCode("");
            setdataBindCode([]);
            return;
          }
          setBinCode(e.key);

          const filter = data.filter((item) => item.bin.binCode === e.key);
          setdataBindCode(filter);
        },
      });
    });

    return (
      <Dropdown className={styles.dropdownBin} menu={{ items }} trigger={["click"]}>
        <div style={{ cursor: "pointer" }}>
          <span style={{ marginRight: 8 }}>Bin Code</span>
          <FilterIcon color={binCode ? `#F36F21` : "unset"} />
        </div>
      </Dropdown>
    );
  };

  // Title Position
  const TitlePosition = (): JSX.Element => {
    const items: MenuProps["items"] | Array<any> = [];
    listPosition.forEach((item) => {
      items.push({
        label: <div>{item.label === position ? <span style={{ color: "#F36F21" }}>{item.label}</span> : <span>{item.label}</span>}</div>,
        key: item.label,
        onClick: (e) => {
          if (e.key === position) {
            setPosition("");
            setdataPosition([]);
            return;
          }
          setPosition(e.key);

          const filter = data.filter((item) => item.position === e.key);
          setdataPosition(filter);
        },
      });
    });

    return (
      <Dropdown className={styles.dropdownBin} menu={{ items }} trigger={["click"]}>
        <div style={{ cursor: "pointer" }}>
          <span style={{ marginRight: 8 }}>Position</span>
          <FilterIcon color={position ? `#F36F21` : "unset"} />
        </div>
      </Dropdown>
    );
  };

  const dataFilterd = (): any[] => {
    let arrayAfterFilter: any[] = [];
    if (binCode === "" && position === "") {
      arrayAfterFilter = data ?? [];
    }
    if (binCode) {
      arrayAfterFilter = dataBindCode;
    }
    if (position) {
      arrayAfterFilter = dataPosition;
    }
    if (binCode !== "" && position !== "") {
      const filter = data.filter((item) => item.position === position && item.bin.binCode === binCode);
      arrayAfterFilter = filter;
    }

    return arrayAfterFilter;
  };

  // Export QR list
  const handlePrint = useReactToPrint({
    content: () => {
      return refPrint.current;
    },
    pageStyle: `

    @page {
      margin: 0;
      size: 76.2mm 25.4mm;
      padding: 1mm;
      
    }`,
    documentTitle: "Product management",
  });

  const exportPrint = () => {
    setExportExcel(!exportExcel);
    setSelectAllPrint(exportExcel);
    setSelectedRowKeys([]);
    setSelectPrintList([]);
    setQuantitySelected(0);
    setDisabled(true);
  };

  // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-unused-vars
  const HideAllSelectedItem = ({ dataPrint }: { dataPrint: Array<any> }): JSX.Element => {
    return (
      <div
        style={{
          marginBottom: 12,
          marginLeft: 4,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <p
            style={{
              margin: 0,
              marginRight: 16,
              fontWeight: 500,
            }}
          >
            {quantitySelected >= 2 ? (
              <>
                <span style={{ fontWeight: 600, paddingRight: 4 }}>{quantitySelected} </span> Site products were selected
              </>
            ) : (
              <>
                <span style={{ fontWeight: 600 }}>{quantitySelected} </span> Site product was selected
              </>
            )}
          </p>
        </div>
        <Button
          style={{
            border: 0,
            backgroundColor: "#4877dd",
            color: "#fff",
            fontSize: 12,
            padding: "6px 12px",
            fontWeight: 600,
            borderRadius: 5,
            cursor: "pointer",
          }}
          disabled={disable}
          onClick={() => handlePrint()}
        >
          Export QR
        </Button>
        <Button
          style={{
            border: 0,
            backgroundColor: "#8591a5",
            color: "#fff",
            fontSize: 12,
            padding: "6px 12px",
            fontWeight: 600,
            borderRadius: 5,
            cursor: "pointer",
            marginLeft: "0.5rem",
          }}
          onClick={() => exportPrint()}
        >
          Cancel
        </Button>
      </div>
    );
  };

  const rowSelector: TableRowSelection<any> = {
    onChange: (selectedKeys: React.Key[], selectedRows: any) => {
      setSelectedRowKeys(selectedKeys);
      setSelectPrintList(selectedRows);
      setQuantitySelected(selectedRows.length);
      if (selectedRows?.length) setDisabled(false);
      else setDisabled(true);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.deletedAt,
    }),
    // eslint-disable-next-line object-shorthand
    selectedRowKeys: selectedRowKeys,
  };

  return (
    <ContentWrapper
      dataBreadCrumb={[
        {
          title: routes.siteProductList.title,
          icon: <BoxIcon />,
          url: routes.siteProductList.path,
        },
      ]}
      renderFilter={
        <FilterComponent type="list" onSelectCompany={handleSelectCompany} onSelectWareHouse={handleSelectWarehouse} onPrint={() => exportPrint()} />
      }
      headerContent={
        <div
          style={{
            display: "flex",
            justifyContent: exportExcel ? "space-between" : "end",
          }}
        >
          {exportExcel && <HideAllSelectedItem dataPrint={selectPrintList} />}
          <SearchArea
            value={search}
            onChange={(value: string) => {
              setSearch(value);
              setPage(1);
            }}
          />
        </div>
      }
    >
      <TableCustom
        rowSelection={
          exportExcel
            ? {
                type: "checkbox",
                ...rowSelector,
              }
            : undefined
        }
        data={dataFilterd()}
        columns={columns({
          onDelete: handleDeletePopup,
          onRestore: (id) => handleDeleteRestore(id, "restore"),
          loadingList,
          titleBinCode: <TitleBinCode />,
          titlePosition: <TitlePosition />,
        })}
        total={checkTotalAllItem({
          page,
          companyId: companySelected?.id || "",
          search: wareHouseSelected?.identityCode || "",
        })}
        loading={loading}
      />
      {currentData && Number(currentData?.page) < Number(currentData?.totalPage) && (
        <Row
          ref={ref}
          justify="center"
          style={{
            padding: 36,
          }}
        >
          <Spin tip="Loading" size="large" />
        </Row>
      )}
      {/* HTML Print */}
      <div
        className="print-source"
        ref={refPrint}
        style={{
          height: "25.4mm",
        }}
      >
        {(selectPrintList || []).map(
          (product: ProductType, index: number) =>
            !product.deletedAt && (
              // <Col
              //   span={24}
              //   style={{
              //     borderWidth: 1,
              //     borderColor: "#000",
              //     display: "flex",
              //     justifyContent: "flex-start",
              //     alignItems: "center",
              //     marginBottom: 10,
              //   }}
              // >
              <div
                style={{
                  padding: 2,
                  paddingTop: 3,
                  pageBreakAfter: "always",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Card
                  key={index}
                  style={{
                    borderWidth: 1,
                    borderColor: "#CCCCCC",
                    marginBottom: 4,
                    width: "100% ",
                    height: "100%",
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingBlock: 4,
                    pageBreakAfter: "always",
                  }}
                  bodyStyle={{ padding: "0px 2px 0px 2px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 5,
                      fontWeight: "bold",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "auto" }}>
                      <QRCodeCanvas style={{ marginBottom: 3 }} size={60} value={JSON.stringify({ productId: product.id })} />
                    </div>
                    <div style={{ textAlign: "left", marginLeft: 5, overflow: "hidden" }}>
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: 15,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {product?.masterProduct?.manufactureItemNumber}
                      </div>
                      <div
                        className={clsx(styles.productName)}
                        style={{
                          fontSize: "0.59375rem",
                          whiteSpace: "pre-wrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {product?.productName}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      fontSize: 10,
                      paddingTop: "4px",
                      fontWeight: "bold",
                    }}
                  >
                    <div>W/H:{product.bin.warehouse?.identityCode}</div>
                    <div>Bin: {product?.bin?.binCode}</div>
                    <div>
                      Pos:
                      {product.position}
                    </div>
                  </div>
                </Card>
              </div>
              // </Col>
            )
        )}
      </div>
    </ContentWrapper>
  );
}

export default SiteProductManagementModule;
