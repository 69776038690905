import { useGetMasterProductUnitListQuery } from "@/rtk-query/masterProductQueries";
import { useGetUserInfoQuery } from "@/rtk-query/userQueries";
import C from "@/utils/constants";
import { CSSProperties } from "react";

const isClient = typeof window !== "undefined";

export const uuid = () => {
  let dt = new Date().getTime();
  const id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 || 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r && 0x3) || 0x8).toString(16);
  });
  return id;
};

// Local Storage
export const getLocalStorage = (key: string) => {
  try {
    if (isClient) {
      const value = localStorage.getItem(key);
      return value ?? undefined;
    }
  } catch (error) {}
};

export const setLocalStorage = (key: string, value: any) => {
  try {
    if (isClient) {
      return localStorage.setItem(key, value);
    }
  } catch (error) {}
};
//

// Check Auth
export const isAuthenticated = () => {
  const token = localStorage.getItem(C.STORAGE_TOKEN);
  if (token) {
    return true;
  }
  return false;
};

export const isAuthorized = (platformAccepted: Array<string>) => {
  const platform = ["ONLY_ADMIN_SITE", "ALL"];
  return platformAccepted.filter((item: string) => platform.includes(item)).length > 0;
};

export const useCheckPermission = (action?: typeof C.PERMISSIONS[keyof typeof C.PERMISSIONS]) => {
  if (!action) return true;
  // eslint-disable-next-line
  const { currentData } = useGetUserInfoQuery();
  return currentData?.isSuperAdmin || (currentData?.scopes || []).includes(action);
};

export const useGetUnitByKey = (key: string) => {
  const { currentData: masterProductUnitList } = useGetMasterProductUnitListQuery({ isLoading: undefined });

  return (
    (masterProductUnitList?.data || []).find((unit) => unit.key === key) || {
      key,
      name: key,
    }
  );
};
//

// Common Func
export const autoGeneratePassword = () => {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const passwordLength = 12;
  let password = "";

  for (let i = 0; i <= passwordLength; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }

  return password;
};

export const cleanObject = (obj: any) => {
  const _obj = { ...obj };
  // eslint-disable-next-line
  for (const [key, value] of Object.entries(_obj)) {
    if (typeof value !== "boolean" && !value) {
      delete _obj[key];
    }
  }
  return _obj;
};

export const getError = (error: any) => {
  let message = "";
  if (!navigator.onLine) {
    message = "Error. Please try again!";
  } else if (typeof error?.data?.errors === "object" && Array.isArray(error?.data?.errors) && error?.data?.errors.length > 0) {
    message = error?.data?.errors?.[0];
  } else {
    message = error?.data?.errors || error?.data?.message || error?.error;
  }
  return message;
};

export const removeVietnameseTones = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
};

export const unsecuredCopyToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
};

export const checkTotalAllItem = (filter: any) => {
  const { page, pageSize, ...rest } = filter;
  if (page > 1) return 1;
  return Object.keys(cleanObject(rest)).length;
};

export const downloadFile = (blobReponse: Blob, name: string) => {
  const hiddenElement = document.createElement("a");
  const url = window.URL || window.webkitURL;
  const blobXlsx = url.createObjectURL(blobReponse);
  hiddenElement.href = blobXlsx;
  hiddenElement.target = "_blank";
  hiddenElement.download = name;
  hiddenElement.click();
};

export const objectToFormData = (object: any) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

export const renderFieldViewOnly = (value?: string | number, style?: CSSProperties) => {
  return (
    <div
      style={{
        fontSize: 12,
        color: "#234c66",
        fontWeight: 600,
        padding: "0px 11px",
        ...style,
      }}
    >
      {value ?? ""}
    </div>
  );
};

// eslint-disable-next-line
export const addZero = (value: string | number) => ("0" + value).slice(-2);

export const addParamsRoute = (obj: object) => {
  const objectClean = cleanObject(obj);
  const searchParams = new URLSearchParams(objectClean);
  return searchParams;
};
// ====
