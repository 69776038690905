// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CommonArray } from "@/utils/types";
import baseApi from ".";
import queryTagTypes from "./queryTagTypes";

const positionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPositionList: builder.query<any, { params: any }>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: (params) => ({
        url: "/products/position",
        method: "GET",
      }),
      providesTags: [queryTagTypes.POSITION_QUERY_TAG],
    }),
  }),
});

export default positionApi;
export const { useGetPositionListQuery } = positionApi;
