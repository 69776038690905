import AuthLayout from "@/components/layouts/AuthLayout";
import routes from "@/routes";
import { Button, Col, Form, Input, notification, Row, Typography } from "antd";
import styles from "./forgotPassword.module.less";
import message from "@/utils/message";
import { useForgotPasswordMutation } from "@/rtk-query/userQueries";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getError, isAuthenticated } from "@/utils/helpers";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect } from "react";

const { Title, Text } = Typography;

function ForgotPasswordModule() {
  const [forgotPasswordApi, { isLoading }] = useForgotPasswordMutation();

  const onFinish = async (values: any) => {
    try {
      await forgotPasswordApi(values).unwrap();
      notification.success({
        message: message.SUCCESSFULLY,
        key: "forgot_password",
      });
    } catch (err) {
      notification.error({
        key: "forgot_password",
        message: getError(err),
      });
    }
  };

  return (
    <AuthLayout title={routes.changePassword.title}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Title
            level={3}
            style={{
              marginBottom: 16,
              fontSize: 25,
              textAlign: "center",
            }}
          >
            Forgot password
          </Title>
          <Text
            className={styles.textBase}
            style={{
              color: "#454545",
              width: "100%",
              textAlign: "center",
              marginBottom: 40,
              display: "block",
            }}
          >
            A new default password will be sent to your email address follows. <br />
            Please change password when you logged in successfully!
          </Text>
        </Col>

        <Col span={16}>
          <Form name="recover-password" className={styles.loginForm} initialValues={{ remember: false }} onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: message.ERROR_NOT_BLANK("This field"),
                },
              ]}
              style={{
                marginBottom: "0px !important",
              }}
            >
              <Input size="large" type="email" placeholder="Email..." className={styles.textField} />
            </Form.Item>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    width: "100%",
                    marginTop: 40,
                  }}
                  size="large"
                  disabled={isLoading}
                >
                  <Title
                    level={5}
                    style={{
                      color: "#fff",
                      fontSize: 15,
                    }}
                  >
                    Recover password
                  </Title>
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Col>
      </Row>
    </AuthLayout>
  );
}

export default ForgotPasswordModule;
