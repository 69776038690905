import TableCustom from "@/components/TableCustom";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import { Columns } from "./masterProductManagement.constants";
import { checkTotalAllItem, getError } from "@/utils/helpers";
import { useEffect, useRef, useState } from "react";
import { MasterProductType } from "@/utils/types";
import C from "@/utils/constants";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { Row, Spin, notification } from "antd";
import routes from "@/routes";
import MultiBoxIcon from "@/assets/icons/MultiBoxIcon";
import FilterComponent from "./components/FilterComponent";
import SearchArea from "./components/SearchArea";
import { Toast } from "@/app/store";
import { useDeleteMasterProductMutation, useGetMasterProductListQuery, useRestoreMasterProductMutation } from "@/rtk-query/masterProductQueries";
import useDebounce from "@/hooks/useDebounce";
import message from "@/utils/message";

function MasterProductManagementModule() {
  const [data, setData] = useState<Array<MasterProductType>>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 300);
  const [loadingList, setLoadingList] = useState<Array<string | number>>([]);

  const { currentData, isFetching, refetch } = useGetMasterProductListQuery({
    page,
    search: searchDebounce.trim(),
    pageSize: C.PAGE_SIZE,
    isLoading: undefined,
  });
  const [deleteMasterProductApi] = useDeleteMasterProductMutation();
  const [restoreMasterProductApi] = useRestoreMasterProductMutation();

  const handleLoaded = (id: string | number) => {
    setTimeout(() => {
      setLoadingList(loadingList.filter((item) => item !== id));
    }, 300);
  };

  const handleDeleteRestoreMasterProduct = async (id: string | number, type?: "delete" | "restore") => {
    setLoadingList([...loadingList, id]);
    Toast.close();

    try {
      type === "delete" ? await deleteMasterProductApi(id).unwrap() : await restoreMasterProductApi(id).unwrap();
      notification.success({
        key: "error_master_product_del",
        message: message.SUCCESSFULLY,
      });
      handleLoaded(id);
      setPage(1);
    } catch (error) {
      notification.error({
        key: "error_master_product_del",
        message: getError(error),
      });
      handleLoaded(id);
    }
  };

  const handleDeletePopup = (id: string | number) => {
    Toast.delete({
      isOpen: true,
      title: "Are you sure you want to delete this product?",
      description: "You will not able to view and edit this product’s information.",
      cancelText: "Cancel",
      okText: "Delete",
      onCancel: () => {
        Toast.close();
      },
      onOk: () => handleDeleteRestoreMasterProduct(id, "delete"),
      okStyle: {
        background: "#F32121",
      },
    });
  };

  const handleRefetchMasterProductList = async () => {
    setPage(1);
    setSearch("");

    const contentElement: any = document.getElementById("content");
    contentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Intersection Observer
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    setData(page === 1 ? currentData?.data || [] : [...data, ...(currentData?.data || [])]);
  }, [currentData, isFetching, refetch, page]);

  useEffect(() => {
    if (isVisible) {
      setPage((currentData?.page || 0) + 1);
    }
  }, [isVisible]);

  useEffect(() => {
    setPage(1);
    setSearch("");
    handleRefetchMasterProductList();
  }, []);

  return (
    <ContentWrapper
      title={routes.masterProductList.title}
      hideTitle
      renderFilter={<FilterComponent type="list" />}
      dataBreadCrumb={[
        {
          title: routes.masterProductList.title,
          url: routes.masterProductList.path,
          icon: <MultiBoxIcon />,
        },
      ]}
      headerContent={ <SearchArea
        value={search}
        onChange={(value: string) => {
          setPage(1);
          setSearch(value);
        }}
      />}
    >
      <TableCustom
        data={data || []}
        columns={Columns({
          loadingList,
          onDelete: handleDeletePopup,
          onRestore: (id: string | number) => handleDeleteRestoreMasterProduct(id, "restore"),
        })}
        total={checkTotalAllItem({
          page,
          search,
        })}
      />
      {currentData && Number(currentData?.page) < Number(currentData?.totalPage) && (
        <Row
          ref={ref}
          justify="center"
          style={{
            padding: 36,
          }}
        >
          <Spin tip="Loading" size="large" />
        </Row>
      )}
    </ContentWrapper>
  );
}

export default MasterProductManagementModule;
