import { useEffect, useState } from "react";
import { Button, theme, UploadProps, notification, Upload, Progress, Checkbox } from "antd";
import UploadIcon from "@/assets/icons/UploadIcon";
import { RcFile } from "antd/es/upload";
import { getError, objectToFormData } from "@/utils/helpers";
import { useHistory, useLocation } from "react-router";
import {
  useGetFileDataImportListQuery,
  useGetFileImportQuery,
  useGetStatusImportMasterProductQuery,
  useImportMasterProductMutation,
  useImportProductMutation,
} from "@/rtk-query/fileImportQueries";
import styles from "../../excelImport.module.less";
import { useTypedDispatch, useTypedSelector } from "@/app/store";
import { excelImportSlide } from "../../excelImport.slice";
import clsx from "clsx";

const { Dragger } = Upload;
const { useToken } = theme;

const UploadFileComponent = () => {
  const history = useHistory();
  const location = useLocation();

  const { token } = useToken();

  const dispatch = useTypedDispatch();
  const { updateFileIdImporting } = excelImportSlide.actions;
  const { fileIdImporting } = useTypedSelector((store) => store.excelImportSlide);

  const isMasterProducts = location.pathname.includes("master-products");

  const [importMasterProductApi, { isLoading: isLoadingImportMasterProduct }] = useImportMasterProductMutation();
  const [importSiteProductApi, { isLoading: isLoadingImportSiteProduct }] = useImportProductMutation();

  const [checked, setChecked] = useState<boolean>(true);

  // Check status importing
  const {
    currentData: importStatus,
    refetch: refetchImportStatus,
    isFetching: isFetchingImportStatus,
  } = useGetStatusImportMasterProductQuery({
    type: isMasterProducts ? 1 : 2,
  });
  //

  const loading = Boolean(fileIdImporting) && (isMasterProducts ? importStatus?.type === 1 : importStatus?.type === 2);
  const isImporting = loading || isLoadingImportMasterProduct || isLoadingImportSiteProduct;

  const {
    currentData: fileImport,
    isFetching: isFetchingFileImport,
    refetch: refetchFileImport,
  } = useGetFileImportQuery(
    {
      id: fileIdImporting || "",
    },
    {
      pollingInterval: 3000,
      skip: !loading,
    }
  );

  const { currentData: fileImportList, refetch: refetchFileImportList } = useGetFileDataImportListQuery({
    type: isMasterProducts ? 1 : 2,
    isLoading: undefined,
  });

  const handleImportProduct = async (file: RcFile | undefined) => {
    try {
      let result;
      if (isMasterProducts) {
        result = await importMasterProductApi(objectToFormData({ file, updateIfExist: checked })).unwrap();
      } else {
        result = await importSiteProductApi(objectToFormData({ file, updateIfExist: checked })).unwrap();
      }
      dispatch(updateFileIdImporting(result.id));
    } catch (error) {
      error &&
        notification.error({
          message: getError(error),
          key: "upload_file",
        });
    }
  };

  const handleGotoFileImportDetail = () => {
    dispatch(updateFileIdImporting(null));

    history.push({
      pathname: location.pathname,
      search: `?fileId=${fileImportList?.data?.[0]?.id}`,
    });
  };

  useEffect(() => {
    refetchImportStatus();
    refetchFileImportList();

    if (!loading) return;
    refetchFileImport();
  }, [fileIdImporting]);

  useEffect(() => {
    if (!loading || !fileImport) return;
    if (!fileImport?.processing) {
      handleGotoFileImportDetail();
    }
  }, [fileImport, isFetchingFileImport]);

  useEffect(() => {
    if (isFetchingImportStatus) return;
    dispatch(updateFileIdImporting(importStatus?.id));
  }, [isFetchingImportStatus]);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    fileList: [],
    accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onChange(info) {
      const { originFileObj } = info.file;
      handleImportProduct(originFileObj);
    },
    style: {
      background: "#fff",
    },
  };

  const checkUpdateImport = () => {
    setChecked(!checked);
  };

  return (
    <div className={styles.uploadWrapper}>
      <Dragger {...props} disabled={isImporting}>
        <div className={styles.contentUpload}>
          {isImporting ? <Progress strokeColor={token.colorPrimary} type="circle" percent={Math.floor(fileImport?.percent || 0)} width={80} /> : <UploadIcon />}

          <p
            style={{
              fontSize: isImporting ? 16 : 12,
              fontWeight: 300,
              color: "#8491A6",
              fontStyle: "italic",
              margin: 0,
            }}
          >
            {isImporting ? "Importing..." : "Select or drag files here"}
          </p>
          {!isImporting && (
            <Button
              type="primary"
              size="large"
              style={{
                fontWeight: 600,
                padding: "0 36px",
                fontSize: 15,
                marginBottom: 28,
              }}
            >
              Choose file here
            </Button>
          )}
        </div>
      </Dragger>
      {!isImporting && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Checkbox defaultChecked checked={checked} onChange={checkUpdateImport} id="check-upload" className={clsx("h-auto")} />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="check-upload" style={{ paddingTop: 3, cursor: "pointer" }} className={clsx("h-auto")}>
            On import update the existing Product for matching product ID
          </label>
        </div>
      )}
      {fileImportList?.data?.[0]?.id && !isImporting && (
        <div className={styles.viewLastBtn} onClick={handleGotoFileImportDetail}>
          View last uploaded results
        </div>
      )}
    </div>
  );
};

export default UploadFileComponent;
