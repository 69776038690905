import { Col, Input, Row } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LogoIcon from "@/assets/icons/Logo";
import routes from "@/routes";
import ButtonRectangle from "@/components/ButtonRectangle";
import useDebounce from "@/hooks/useDebounce";
import styles from "../../clientManagement.module.less";

interface IFilterComponentProps {
  onSearch: (value: string) => void;
  onExport: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  isloadingExportCompany: boolean;
}

function FilterComponent({ onSearch, onExport }: IFilterComponentProps) {
  const [companyName, setCompanyName] = useState("");
  const companyNameDebounce = useDebounce(companyName, 300);

  useEffect(() => {
    onSearch(companyNameDebounce || "");
  }, [companyNameDebounce]);

  return (
    <Row justify="space-between" align="middle" className={styles.filterArea}>
      <Col
        flex={1}
        style={{
          height: "100%",
        }}
      >
        <div className={styles.leftContent}>
          <div className={styles.logoWrapper}>
            <LogoIcon />
            <span
              style={{
                fontSize: 14,
                color: "#234C66",
                fontWeight: 700,
              }}
            >
              {routes.clientList.title}
            </span>
          </div>

          <Input placeholder="Company name..." value={companyName} onChange={(event) => setCompanyName(event.target.value)} className={styles.searchInput} />
        </div>
      </Col>
      <Col
        style={{
          height: "100%",
        }}
      >
        <div className={styles.rightContent}>
          <Link to={routes.createClient.path}>
            <ButtonRectangle>Add new client</ButtonRectangle>
          </Link>

          <ButtonRectangle
            style={{
              background: "#8491A6",
            }}
            onClick={onExport}
          >
            Export Excel file
          </ButtonRectangle>
        </div>
      </Col>
    </Row>
  );
}

export default FilterComponent;
