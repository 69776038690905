import { useEffect, useRef, useState } from "react";
import { Row, Spin, notification } from "antd";
import TableCustom from "@/components/TableCustom";
import ContentWrapper from "@/components/layouts/DashboardLayout/Content";
import { columns } from "@/modules/ClientManagement/clientManagement.constants";
import FilterComponent from "./components/FilterComponent";
import routes from "@/routes";
import PersonIcon from "@/assets/icons/PersonIcon";
import { useDeleteCompanyMutation, useGetCompaniesQuery, useRestoreCompanyMutation } from "@/rtk-query/companyQueries";
import { Toast } from "@/app/store";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import C from "@/utils/constants";
import message from "@/utils/message";
import { checkTotalAllItem, getError } from "@/utils/helpers";
import { useExportCompanyExcelMutation, useExportUserMutation } from "@/rtk-query/userQueries";
import { useExportWarehouseMutation } from "@/rtk-query/warehouseQueries";

function ClientManagementModule() {
  const [data, setData] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [loadingList, setLoadingList] = useState<Array<string | number>>([]);

  const { currentData, isLoading } = useGetCompaniesQuery({
    search,
    page,
    pageSize: C.PAGE_SIZE,
    isLoading: undefined,
  });

  const [deleteCompanyApi] = useDeleteCompanyMutation();
  const [restoreCompanyApi] = useRestoreCompanyMutation();
  const [exportUserApi, { isLoading: isLoadingExportUserApi }] = useExportUserMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [exportCompanyApi, { isLoading: isLoadingExportCompanyApi }] = useExportCompanyExcelMutation();
  const [exportWarehouseApi, { isLoading: isLoadingExportWarehouseApi }] = useExportWarehouseMutation();

  const handleLoaded = (id: string | number) => {
    setTimeout(() => {
      setLoadingList(loadingList.filter((item) => item !== id));
    }, 300);
  };

  const handleDeleteRestoreClient = async (id: string | number, type?: "delete" | "restore") => {
    setLoadingList([...loadingList, id]);
    Toast.close();

    try {
      type === "delete"
        ? await deleteCompanyApi({
            id,
          }).unwrap()
        : await restoreCompanyApi({
            id,
          }).unwrap();
      setPage(1);
      notification.success({
        key: "client_del",
        message: message.SUCCESSFULLY,
      });
      handleLoaded(id);
    } catch (err) {
      notification.error({
        key: "client_del",
        message: getError(err),
      });
      handleLoaded(id);
    }
  };

  const handleDeletePopup = (id: string | number) => {
    Toast.delete({
      isOpen: true,
      title: "Are you sure you want to delete this client?",
      description: "You will not able to view and edit this client’s information.",
      cancelText: "Cancel",
      okText: "Delete",
      onCancel: () => {
        Toast.close();
      },
      onOk: () => handleDeleteRestoreClient(id, "delete"),
      okStyle: {
        background: "#F32121",
      },
    });
  };

  const handleExport = async (companyId: string | number, type: "warehouse" | "user" | "product") => {
    // return;
    try {
      if (type === "user") {
        await exportUserApi({
          companyId,
        });
      }

      if (type === "warehouse") {
        await exportWarehouseApi({
          companyId,
        });
      }

      notification.success({
        key: "client_export",
        message: message.SUCCESSFULLY,
      });
    } catch (error) {
      notification.error({
        key: "client_export",
        message: getError(error),
      });
    }
  };

  const handleExportExcel = async () => {
    try {
      await exportCompanyApi({
        page: 1,
        pageSize: 100,
      });
      notification.success({
        key: "client_export",
        message: message.SUCCESSFULLY,
      });
    } catch (error) {
      notification.error({
        key: "client_export",
        message: getError(error),
      });
    }
  };

  const handleSearch = async (value: string) => {
    setPage(1);
    setSearch(value);
  };

  // Intersection Observer
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    setData(page === 1 ? currentData?.data || [] : [...data, ...(currentData?.data || [])]);
  }, [currentData, page]);

  useEffect(() => {
    if (isVisible) {
      setPage((currentData?.page || 0) + 1);
    }
  }, [isVisible]);

  return (
    <ContentWrapper
      title="LIST OF CLIENTS"
      renderFilter={<FilterComponent onSearch={handleSearch} onExport={handleExportExcel} isloadingExportCompany={isLoadingExportCompanyApi} />}
      dataBreadCrumb={[
        {
          title: routes.clientList.title,
          icon: <PersonIcon />,
          url: routes.clientList.path,
        },
      ]}
    >
      <TableCustom
        data={data}
        columns={columns({
          onDelete: handleDeletePopup,
          onRestore: (id) => handleDeleteRestoreClient(id, "restore"),
          onExport: handleExport,
          isLoadingExportUserApi,
          isLoadingExportWarehouseApi,
          loadingList,
        })}
        total={checkTotalAllItem({ page, search })}
        loading={isLoading}
      />
      {currentData && Number(currentData?.page) < Number(currentData?.totalPage) && (
        <Row
          ref={ref}
          justify="center"
          style={{
            padding: 36,
          }}
        >
          <Spin tip="Loading" size="large" />
        </Row>
      )}
    </ContentWrapper>
  );
}

export default ClientManagementModule;
