import { Content } from "antd/es/layout/layout";
import { ReactNode, useEffect } from "react";
import { Typography } from "antd";
import Header from "../Header";
import styles from "../dashboardLayout.module.less";
import { BreadcrumbItemType } from "../Header/header.types";

const { Title } = Typography;

interface IContentWrapperProps {
  title?: string;
  hideTitle?: boolean;
  children: ReactNode;
  dataBreadCrumb?: Array<BreadcrumbItemType>;
  renderFilter?: ReactNode;
  headerContent?: ReactNode | JSX.Element
}

function ContentWrapper({ title = "", hideTitle = false, children = null, dataBreadCrumb = [], renderFilter = null, headerContent = null }: IContentWrapperProps) {
  useEffect(() => {
    document.title = title || "Dashboard";
  }, [title]);

  return (
    <>
      <Header dataBreadCrumb={dataBreadCrumb || []} renderFilter={renderFilter} />
      <Content className={styles.contentWrapper}>
      {headerContent && (
        <div className={styles.contentHeader}>
          {headerContent}
        </div>
      )}
        <div id="content" className={headerContent ? styles.contentBody: styles.contentBodyNoneHeader}>
          {!hideTitle && title && (
            <Title
              level={5}
              style={{
                marginBottom: 20,
                color: "#234C66",
                textTransform: "uppercase",
              }}
            >
              {title}
            </Title>
          )}
          <div
            style={{
              // display: "table",
              maxWidth: "100%",
            }}
          >
            {children}
          </div>
        </div>
      </Content>
    </>
  );
}

export default ContentWrapper;
