import DeleteIcon from "@/assets/icons/DeleteIcon";
import EditIcon from "@/assets/icons/EditIcon";
import NextIcon from "@/assets/icons/NextIcon";
import routes from "@/routes";
import { useGetMasterProductUnitListQuery } from "@/rtk-query/masterProductQueries";
import C from "@/utils/constants";
import { useCheckPermission } from "@/utils/helpers";
import { ProductType } from "@/utils/types";
import { Image, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";

export const Columns = ({ onDelete, onRestore, loadingList }: { onDelete?: any; onRestore?: any; loadingList?: Array<string | number> }) => {
  const canEditProduct = useCheckPermission(C.PERMISSIONS.MASTER_PRODUCT_EDIT);
  const canDeleteProduct = useCheckPermission(C.PERMISSIONS.MASTER_PRODUCT_DELETE);

  const { currentData: masterProductUnitList } = useGetMasterProductUnitListQuery({ isLoading: undefined });
  const findUnit = (key: string) =>
    (masterProductUnitList?.data || []).find((unit) => unit.key === key) || {
      key,
      name: key,
    };

  return [
    {
      title: "Item Code",
      dataIndex: "manufactureItemNumber",
      key: "manufactureItemNumber",
      width: 180,
      render: (value, record) => (
        <div
          style={{
            maxWidth: 180,
            color: record?.deletedAt ? "#979797" : "#000000E0",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 180,
      render: (value, record) => (
        <div
          style={{
            maxWidth: 180,
            color: record?.deletedAt ? "#979797" : "#000000E0",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      width: 180,
      render: (value, record) => (
        <Image
          src={value?.[0]?.imageUrl}
          style={{
            width: 40,
            opacity: record?.deletedAt ? 0.3 : 1,
          }}
          loading="eager"
          fallback={C.IMAGE_DEFAULT}
          preview={!record?.deletedAt}
        />
      ),
    },
    {
      title: "Supplier",
      dataIndex: "manufacture",
      key: "manufacture",
      width: 180,
      render: (value, record) => (
        <div
          style={{
            maxWidth: 180,
            color: record?.deletedAt ? "#979797" : "#000000E0",
          }}
        >
          {value}
        </div>
      ),
    },
    // {
    //   title: "Manufacture Item Number",
    //   dataIndex: "manufactureItemNumber",
    //   key: "manufactureItemNumber",
    //   width: 180,
    //   render: (value, record) => (
    //     <div
    //       style={{
    //         maxWidth: 180,
    //         color: record?.deletedAt ? "#979797" : "#000000E0",
    //       }}
    //     >
    //       {value}
    //     </div>
    //   ),
    // },
    {
      title: "Product Group",
      dataIndex: "productGroup",
      key: "productGroup",
      width: 180,
      render: (value, record) => (
        <div
          style={{
            maxWidth: 180,
            color: record?.deletedAt ? "#979797" : "#000000E0",
          }}
        >
          {value !== undefined ? value : ""}
        </div>
      ),
    },
    // {
    //   title: "Max Par Level	",
    //   dataIndex: "minimumQuantity",
    //   key: "minimumQuantity",
    //   render: (value, record) => (
    //     <div
    //       style={{
    //         maxWidth: 180,
    //         color: record?.deletedAt ? "#979797" : "#000000E0",
    //       }}
    //     >
    //       {value}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Reorder Multiple",
    //   dataIndex: "reorderMultiple",
    //   key: "reorderMultiple",
    //   render: (value, record) => (
    //     <div
    //       style={{
    //         maxWidth: 180,
    //         color: record?.deletedAt ? "#979797" : "#000000E0",
    //       }}
    //     >
    //       {value}
    //     </div>
    //   ),
    // },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (value, record) => (
        <div
          style={{
            maxWidth: 180,
            color: record?.deletedAt ? "#979797" : "#000000E0",
          }}
        >
          {findUnit(value || "")?.name}
        </div>
      ),
    },
    {
      title: "Functions",
      render: (_, record) => {
        const isLoading = !!(loadingList || []).find((item) => item === record?.id);

        return (
          <>
            {record?.deletedAt ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                  color: "#192EEB",
                  cursor: "pointer",
                  justifyContent: "center",
                  width: "100%",
                }}
                onClick={() => (isLoading || !canDeleteProduct ? undefined : onRestore?.(record?.id))}
              >
                <span>Restore</span>
                <span>&gt;&gt;</span>
              </div>
            ) : (
              <Space
                size="middle"
                style={{
                  maxWidth: 400,
                  fontSize: 12,
                }}
              >
                <Link
                  to={routes.editMasterProduct.path.replace(":id", record?.id || "")}
                  style={{
                    pointerEvents: canEditProduct ? "unset" : "none",
                  }}
                >
                  <EditIcon
                    style={{
                      width: 16,
                      cursor: canEditProduct ? "pointer" : "not-allowed",
                    }}
                  />
                </Link>
                <div onClick={() => (isLoading || !canDeleteProduct ? undefined : onDelete?.(record?.id || ""))}>
                  <DeleteIcon
                    style={{
                      width: 16,
                      cursor: canDeleteProduct ? "pointer" : "not-allowed",
                    }}
                  />
                </div>
                <Link to={routes.viewMasterProduct.path.replace(":id", record?.id || "")}>
                  <NextIcon style={{ width: 16, cursor: "pointer" }} />
                </Link>
              </Space>
            )}
          </>
        );
      },
    },
  ] as ColumnsType<ProductType>;
};
